<template>
	<Dialog :dialog="companyDialog" :dialog-width="600" @close="$emit('close', true)">
		<template v-slot:title>Create Company</template>
		<template v-slot:body>
			<v-form
				ref="form"
				id="form"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="update_or_create"
			>
				<v-row>
					<v-col md="3" class="mt-2">
						<label class="fw-500 required">Name </label>
					</v-col>
					
					
							<!-- <AutoCompleteInput
								style="max-width: 80px; min-width: 80px; overflow: hidden"
								dense
								id="reminder-type"
								hide-details
								placeholder="Salutation"
								:items="salutation_type_items"
								:disabled="pageLoading"
								:loading="pageLoading"
								item-text="text"
								v-model="company.salutation"
								item-value="value"
								v-on:change="get_reminder_values()"
							>
							</AutoCompleteInput> -->
							<v-col md="9" class="mb-0">
									<div class="cust-req">
										<TextValidateInput
										     hide-details
											:disabled="pageLoading"
											:hideTopMargin="true"
											:loading="pageLoading"
											:rules="[vrules.required(company.company_name, 'Company Name')]"
																	:class="{
																		required: !company.company_name,
																	}"
											id="company-name"
											placeholder="Company Name"
											:validationField="{
												url_type: 'customer',
												filter_type: 'contact',
												field: 'company_name',
											}"
											required
											:parent-id="company.id"
											:current-id="company.id"
											show-dropdown
											v-model="company.company_name"
											v-on:keyup="appendDisplayName()"
											
											></TextValidateInput>
										
									</div>
								</v-col>

							<!-- <v-text-field
								depressed
								hide-details
								outlined
								placeholder="Name"
								v-model="company.company_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(company.company_name, 'Name')]"
								:class="{
									required: !company.company_name,
								}"
							></v-text-field> -->
						
				
					<v-col md="3" class="mt-2">
						<label class="fw-500">Email</label>
					</v-col>
					<v-col md="9" class="mb-3">
						<TextInput
							dense
							id="mobile"
							hide-details
							type="email"
							placeholder="Email"
							:disabled="pageLoading"
							:loading="pageLoading"
							class="mt-0"
							v-model="company.email"
						></TextInput>
					</v-col>
					<v-col md="3" class="mt-2">
						<label class="fw-500">Mobile</label>
					</v-col>
					<v-col md="9">
						<PhoneTemplate
							:disabled="pageLoading"
							id="mobile"
							placeholder="Mobile Number"
							class-name="mt-0"
							v-model="company.phone"
							:loading="pageLoading"
							@loading="($event) => (pageLoading = $event)"
							@blur="validatePhone(cindex)"
							@validity="phoneValid($event, cindex, 'Mobile')"
							@clear="company.phone = null"
						></PhoneTemplate>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="cancel_btn"
				depressed
				tile
				:disabled="pageLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
			<v-btn
				class="white--text save_btn"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="update_or_create()"
			>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import TextValidateInput from "@/view/components/TextValidateInput.vue";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
export default {
	name: "contact-template",
	title: "Contact Template",
	props: {
		companyDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			salutation_type_items: ["Mr", "Mrs"],
			company: {
				id: 0,
				salutation: null,
				contact_name: null,
				email: null,
				phone: null,
			},
		};
	},

	components: {
		Dialog,
		TextValidateInput,
		// AutoCompleteInput,
		TextInput,
		PhoneTemplate: () => import("@/view/components/Phone"),
	},

	methods: {
		saveData() {
			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `create-company`, data: this.company })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company added successfully." },
					]);
					this.$refs.form.reset();
					this.$emit("success", data);

					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.reminderSingleData && this.reminderSingleData.id) {
				this.updateData();
			} else {
				this.saveData();
			}
		},
	},
};
</script>
