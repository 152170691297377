var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"dialog":_vm.companyDialog,"dialog-width":600},on:{"close":function($event){return _vm.$emit('close', true)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Create Company")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"form",attrs:{"id":"form","lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.update_or_create.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"md":"3"}},[_c('label',{staticClass:"fw-500 required"},[_vm._v("Name ")])]),_c('v-col',{staticClass:"mb-0",attrs:{"md":"9"}},[_c('div',{staticClass:"cust-req"},[_c('TextValidateInput',{class:{
																	required: !_vm.company.company_name,
																},attrs:{"hide-details":"","disabled":_vm.pageLoading,"hideTopMargin":true,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.company.company_name, 'Company Name')],"id":"company-name","placeholder":"Company Name","validationField":{
											url_type: 'customer',
											filter_type: 'contact',
											field: 'company_name',
										},"required":"","parent-id":_vm.company.id,"current-id":_vm.company.id,"show-dropdown":""},on:{"keyup":function($event){return _vm.appendDisplayName()}},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})],1)]),_c('v-col',{staticClass:"mt-2",attrs:{"md":"3"}},[_c('label',{staticClass:"fw-500"},[_vm._v("Email")])]),_c('v-col',{staticClass:"mb-3",attrs:{"md":"9"}},[_c('TextInput',{staticClass:"mt-0",attrs:{"dense":"","id":"mobile","hide-details":"","type":"email","placeholder":"Email","disabled":_vm.pageLoading,"loading":_vm.pageLoading},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"md":"3"}},[_c('label',{staticClass:"fw-500"},[_vm._v("Mobile")])]),_c('v-col',{attrs:{"md":"9"}},[_c('PhoneTemplate',{attrs:{"disabled":_vm.pageLoading,"id":"mobile","placeholder":"Mobile Number","class-name":"mt-0","loading":_vm.pageLoading},on:{"loading":($event) => (_vm.pageLoading = $event),"blur":function($event){return _vm.validatePhone(_vm.cindex)},"validity":function($event){return _vm.phoneValid($event, _vm.cindex, 'Mobile')},"clear":function($event){_vm.company.phone = null}},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"cancel_btn",attrs:{"depressed":"","tile":"","disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"white--text save_btn",attrs:{"loading":_vm.pageLoading,"disabled":!_vm.formValid || _vm.pageLoading,"depressed":"","color":"blue darken-4","tile":""},on:{"click":function($event){return _vm.update_or_create()}}},[_vm._v(" Save ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }