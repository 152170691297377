<template>
	<div class="v-data-table bt-table theme--light">
		<!-- <pre>
	{{sortedThead}}
</pre> -->

		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>

		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" ref="btt_height">
				<table class="w-100">
					<thead>
						<tr>
							<th v-for="(th, th_idx) in sortedThead" :key="th.key + '_' + th_idx" :width="th.width">
								<template v-if="th.checkbox">
									<v-checkbox
										dense
										v-model="checkAll"
										:ripple="false"
										hide-details
										color="blue darken-4"
										class="m-0 p-0"
									></v-checkbox>
								</template>
								<template v-else>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="companyContact.length">
							<tr
								v-for="(td, td_idx) in companyContact"
								:key="'domain_' + td.id + '_' + td_idx"
								:class="getAciveNotes(td.id)"
							>
								<template v-for="(th, th_idx) in sortedThead">
									<td :key="'domain_data_$_' + th.key + '_' + th_idx" :width="th.width">
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												:ripple="false"
												:value="td.id"
												hide-details
												v-model="selectedRows"
												multiple
												@change="selectRow"
												class="m-0 p-0"
												color="blue darken-4"
											></v-checkbox>
										</template>

										<template v-else-if="th.key == 'action'">
											<v-menu offset-y left max-width="100">
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed fab x-small color="default" v-bind="attrs" v-on="on">
														<v-icon>mdi-dots-vertical</v-icon>
													</v-btn>
												</template>
												<v-list dense>
													<v-list-item
														v-for="(item, index) in actionList"
														:key="index"
														@click="openAction(item, td.id)"
													>
														<v-list-item-title class="cursor-pointer"
															><v-icon small class="me-2">{{ item.icon }}</v-icon
															>{{ item.title }}</v-list-item-title
														>
													</v-list-item>
												</v-list>
											</v-menu>
											<!-- <div class="d-flex justify-space-around align-center">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															@click="openAction('edit', td.id)"
															v-on="on"
															v-bind="attrs"
															class="cursor-pointer"
															color="blue"
															>mdi-pencil
														</v-icon>
													</template>
													<span>Edit Company</span>
												</v-tooltip>

												

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															v-on="on"
															@click.prevent.stop="openAction('delete', td.id)"
															v-bind="attrs"
															class="cursor-pointer"
															color="red"
														>
															mdi-delete
														</v-icon>
													</template>
													<span>Delete Company</span>
												</v-tooltip>
											</div> -->
										</template>

										<template v-else-if="th.key == 'department'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.department">
														<span style="text-transform: uppercase">{{ td.department }}</span>
													</div>
													<div v-else>
														<em class="text-muted">No department</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'email'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.email_address">
														<v-icon class="me-1" small>mdi-email</v-icon
														><span class="cursor-pointer" v-on:click="copyNumber(td.email_address, 'Email')">{{
															td.email_address
														}}</span>
													</div>
													<div v-else>
														<em class="text-muted">no Email</em>
													</div>
												</div>
											</div>
										</template>
										<template v-else-if="th.key == 'mobile'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.phone_number">
														<v-icon small>mdi-phone</v-icon
														><span class="cursor-pointer" v-on:click="copyNumber(td.phone_number, 'Phone')">{{
															td.phone_number
														}}</span>
													</div>
													<div v-else>
														<em class="text-muted">no mobile</em>
													</div>
												</div>
											</div>
										</template>
										<template v-else-if="th.key == 'designation'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.designation">
														{{ td.designation }}
													</div>
													<div v-else>
														<em class="text-muted">no designation</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'company'">
											<div class="d-flex">
												<v-icon class="me-1" small>mdi-domain</v-icon>
												<ShowValue
													:object="td?.company"
													object-key="company_name"
													label="Company Name"
												></ShowValue>
											</div>
										</template>

										<template v-else-if="th.key == 'name'">
											<v-row align="center">
												<v-col class="d-flex align-center" cols="auto">
													<v-avatar class="me-2" color="primary" size="30">
														<span class="text-white">{{ getFirstTwoLetters(td.contact_name) }}</span>
													</v-avatar>
													<span class="me-2">{{ td.salutation }} </span>
													<ShowValue :object="td" object-key="contact_name" label="Contact Name"></ShowValue>
												</v-col>
											</v-row>
										</template>

										<template v-else-if="th.key == 'added_at'">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
														formatTime2(td.added_at)
													}}</v-chip>
												</template>
												<span>Created date</span>
											</v-tooltip>
										</template>

										<template v-else-if="th.key == 'added_by'">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-chip v-bind="attrs" v-on="on" pill outlined>
														<v-avatar left>
															<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="John"></v-img>
														</v-avatar>
														{{ td?.added_by?.display_name }}
													</v-chip>
												</template>
												<span>Created by</span>
											</v-tooltip>
										</template>

										<template v-else-if="th.key == 'tag'">
											<div class="d-flex align-items-center flex-wrap" v-if="td.tag?.length > 0">
												<div
													v-for="(num, index) in td.tag.slice(0, 2)"
													:key="index"
													class="d-flex align-items-center me-2 mb-1"
												>
													<v-chip size="small" variant="outlined" text-color="white" :color="num.color">
														{{ num.text }}
													</v-chip>
												</div>
												<template v-if="td.tag?.length > 2">
													<span class="ms-1 text-blue">+{{ td.tag?.length - 2 }} more..</span>
												</template>
											</div>
											<template v-else>
												<ShowValue :object="td" object-key="tags" label="tag"></ShowValue>
											</template>
										</template>
										<template v-else-if="th.key == 'status'">
											<div @click.stop class="d-flex justify-space-between align-center"></div>
										</template>

										<template v-else-if="th.key == 'last_update'">
											<Chip class="mr-1" outlined :text="td.updated_at" textColor="green" color="green"></Chip>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no company at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>

		<!-- <v-row class="listing-footer py-2">
			<v-col class="my-auto h6 text-capitalize"
				><b>{{ showingString }}</b>
			</v-col>
	
			<v-col class="my-auto">
				<v-pagination
					v-model="currentPage"
					:length="totalPage"
					class="listing-pagination"
					v-on:input="updatePagination"
				>
				</v-pagination
			></v-col>
		</v-row> -->
		<CreateContact
			v-if="update_contact"
			:id="id"
			:drawer="update_contact"
			title-status="Update"
			v-on:close="update_contact = false"
			:task-data="editTask"
			:attendies="attendieList"
			v-on:createSuccess="getdata()"
		></CreateContact>
	</div>
</template>
<script>
// import { mapGetters } from "vuex";

import CreateContact from "@/view/pages/leads/create/CreateContact";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
// import { GET_LISTING } from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import ShowValue from "@/view/components/ShowValue";
import { SET_MESSAGE, SET_NOTE_FLAG } from "@/core/services/store/common.module";
export default {
	name: "ContactListing",
	components: {
		ShowValue,
		CreateContact,
	},
	props: {
		// statusFilterData: {
		// 	type: Array,
		// },
		companyContact: {
			type: Array,
		},
	},
	data() {
		return {
			actionList: [
				{ title: "View", value: "view", icon: "mdi-eye" },
				{ title: "Edit", value: "edit", icon: "mdi-pencil" },
				{ title: "Delete", value: "delete", icon: "mdi-delete" },
			],
			confirm_dialog: false,
			update_contact: false,
			create_contact: false,
			type: null,
			checkbox: false,
			pageLoading: false,
			totalRows: 0,
			id: 0,
			rowsOffset: 0,

			showingFrom: 0,
			showingTo: 0,
			editTask: {},
			attendieList: {},
			sortedThead: [
				{
					id: 1,
					type: "contact",
					key: "name",
					name: "Contact Name",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 50,
					order: 1,
				},
				{
					id: 2,
					type: "contact",
					key: "email",
					name: "Contact Email",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 2,
				},
				{
					id: 3,
					type: "contact",
					key: "mobile",
					name: "Contact Mobile",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 2,
				},
				{
					id: 3,
					type: "contact",
					key: "tag",
					name: "Tag",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 2,
				},
				{
					id: 4,
					type: "contact",
					key: "added_at",
					name: "Created At",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 3,
				},
				{
					id: 4,
					type: "contact",
					key: "added_by",
					name: "Created By",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 3,
				},
			],
		};
	},
	methods: {
		getAciveNotes(id) {
			if (id == this.notesActive) {
				return "bg-orange";
			}
			return "";
		},
		updatePagination(param) {
			console.log(this.currentPage); // Logging current page value
			console.log(param); // Logging param value

			if (this.currentPage === param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);

			this.$nextTick(() => {
				this.getListingData(); // Call a method to update filtered rows based on new pagination
			});
		},
		// getListingData() {
		// 	this.pageLoading = true;
		// 	const filter = new Object({
		// 		tz: new Date().getTime(),
		// 		page: this.currentPage ? this.currentPage : null,
		// 	});
		// 	if (this.internalType && this.internalValue) {
		// 		filter[this.internalType] = this.internalValue;
		// 	}

		// 	this.$store
		// 		.dispatch(GET_LISTING, { url: "contact", filter })
		// 		.then(() => {
		// 			setTimeout(() => {
		// 				this.calculateHeight();
		// 			}, 500);
		// 		})
		// 		.catch((error) => {
		// 			console.error({ error });
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
		getdata() {
			this.$emit("createSuccess", true);
		},
		openAction(action, id) {
			switch (action.value) {
				case "view":
					this.$router.push({ name: "company-detail", params: { id: 1 } });
					break;
				case "edit":
					this.id = id;
					this.update_contact = true;
					this.$store.commit(SET_NOTE_FLAG, id);
					break;
				case "delete":
					this.id = id;
					this.delURL = `company/${id}`;
					this.confirm_dialog = true;

					break;
			}
		},
		getFirstTwoLetters(name) {
			if (!name) return "";
			return name.slice(0, 2).toUpperCase();
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
	},
	computed: {
		...mapGetters(["notesActive"]),
	},
};
</script>

<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
</style>
